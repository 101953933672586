import React, { FC, useEffect, useState } from 'react';
import { Link, PageProps } from 'gatsby';

import classes from './404.module.scss';

import SEO from '../components/SEO/SEO';
import { Caption404, SeoCaption } from '../constants';
import Hero, { HeroType } from '../components/Hero/Hero';
import Navbar from '../components/Navbar/Navbar';
import Layout from '../components/Layout/Layout';

const NotFoundPage: FC<PageProps> = ({ path }) => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMount) {
    return <div />;
  }
  return (
    <Layout>
      <SEO title={SeoCaption.FOUR_OF_FOUR} />
      <Hero page={HeroType.FOUR_OF_FOUR}>
        <Navbar pathname={path} />
        <div className={classes.TitleContainer}>
          <div className={classes.Title}>{Caption404.HEADER_TITLE}</div>
        </div>
      </Hero>
      <div className={classes.ContentContainer}>
        <p>{Caption404.DESCRIPTION_1}</p>
        <p>
          {Caption404.DESCRIPTION_2}
          <Link to={'/'}>{Caption404.LINK_1}</Link>
          {Caption404.DESCRIPTION_3}
          <Link to={'/contact'}>{Caption404.LINK_2}</Link>
          {Caption404.DESCRIPTION_4}
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
